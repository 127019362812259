<template>
  <div>
    <v-row class="gradient-pricing ma-0"  :class="$vuetify.breakpoint.lgAndUp ? ' ': ''">
      <v-container :class="$vuetify.breakpoint.lgAndUp ? 'common-width': ''">
        <template v-for="(item, index) in pricingObj.pricing">
          <v-row class="ma-0" :class="$vuetify.breakpoint.mdAndDown ? 'apply-column' : ''" :key="`pricing-${index}`">
            <v-col class=" d-flex align-center" xl="6" lg="7" xs="12" sm="12" md="12">
              <v-container>
                <h1 class="font-weight-bold mb-4" :style="$vuetify.breakpoint.smAndUp ? 'color:#ffffff !important;  line-height: 60px;' : 'color:#ffffff !important; '" :class="$vuetify.breakpoint.smAndUp ? 'text-h3': 'text-h6'">{{ item.title }}</h1>
                <p class="mt-1 text-subtitle-1" style="color:#ffffff !important">{{ item.description }}</p>
              </v-container>
            </v-col>
            <v-col xl="6" lg="5" xs="12" sm="12" md="12">
              <v-container>
                <v-img contain aspect-ratio="2" :height="$vuetify.breakpoint.xs ? 200 : 600" :src="item.background_image"></v-img>
              </v-container>
          </v-col>
        </v-row>
        </template>
      </v-container>
    </v-row>
    <!-- <v-container :class="[$vuetify.breakpoint.lgAndUp ? 'common-width1': '', $vuetify.breakpoint.smAndUp? 'pa-14' : 'pa-4']">
      <v-row  :class=" $vuetify.breakpoint.xsOnly ?'apply-column' : ''">
        <v-col xl="3" lg="4" md="6" sm="6" xs="12" class="scaling-element"  v-for="(item, index) in pricingObj.pricing_subscription" :key="`subscription-${index}`">
          <v-container class="rounded-xl pa-0">
            <v-card height="550"  class="rounded-xl">
               <div :class="['img-clip white--text',  index === 0 ?'gradient-card' : index === 1 ? 'gradient-card-advance' :  'gradient-card-ultimate']" style="height:80px">
                 <v-container class="d-flex justify-center">
                  <v-icon color="white" size="35">mdi-currency-inr</v-icon>
                  <div class="white--text text-h4 font-weight-bold">{{item.price }}</div>
                </v-container>
               </div>
               <v-container :class=" $vuetify.breakpoint.lgAndUp ?'pa-10' : ''">
                <p class="font-weight-bold" :class="$vuetify.breakpoint.lgAndUp ? 'text-h5' : 'hext-h5'">{{item.title}} </p>
                  <v-row class="ma-0" :class="$vuetify.breakpoint.mdAndDown ? 'apply-column' : ''">
                      <v-list-item-content class="" :class="$vuetify.breakpoint.smAndUp ? 'text-subtitle-1' : 'text-subtitle-2'">
                      <span v-html="item.description" class="mt-1 text-subtitle-1"></span>
                      </v-list-item-content>
                  </v-row>
               </v-container>
            </v-card>
          </v-container>
        </v-col>
        <v-col xl="3" lg="4" md="6" sm="6" xs="12" class="scaling-element"  v-for="(item, index) in pricingObj.pricing_free_trial" :key="`free-plan-${index}`">
          <v-container class="rounded-xl pa-0">
            <v-card height="550" class="rounded-xl">
               <div class="img-clip  white--text gradient-card-second" style="background-color:#43A047;height:80px">
                 <v-container class="d-flex justify-center">
                    <div class="white--text text-h4 font-weight-bold">{{item.title }}</div>
                </v-container>
               </div>
               <v-container class="flex-container-apply ">
                <v-row align="center">
                <p class="text-center text-h5 pa-15 font-weight-bold color-changes">{{item.description}}</p>
                </v-row>
               </v-container>
               <div class="bubbles">
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
              </div>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container> -->
    <v-container :class="[$vuetify.breakpoint.lgAndUp ? 'common-width1': '', $vuetify.breakpoint.smAndUp? 'pa-14' : 'pa-4']">
      <v-row  :class=" $vuetify.breakpoint.xsOnly ?'apply-column' : ''">
        <v-col xl="3" lg="4" md="6" sm="6" xs="12" class="scaling-element"  v-for="(item, index) in pricingObj.pricing_free_trial" :key="`free-plan-${index}`">
          <v-container class="rounded-xl pa-0">
            <v-card height="660" class="rounded-xl">
               <div class="img-clip  white--text gradient-card-second" style="background-color:#43A047;height:80px">
                 <v-container class="d-flex justify-center">
                    <div class="white--text text-h4 font-weight-bold">{{item.title }}</div>
                </v-container>
               </div>
               <!-- <v-container class="flex-container-apply  d-flex" >
                <v-row class=" d-flex align-center" style="height: 100% !important;">
                  <v-col col="6" class="d-flex flex-column">
                    <div class="d-flex justify-center" style="position: relative;z-index: 1000 !important;">
                      <v-btn color="#000000"
                       class="no-text-transform"
                       width="75"
                       height="26"
                       x-small
                        outlined
                       href="https://play.google.com/store/apps/details?id=com.processdrive.rentass"
                       target="_blank"
                       >Start 1 month free trial </v-btn>
                    </div>
                    <p class="text-center text-h5  font-weight-bold color-changes">{{item.description}}
                    </p>
                  </v-col>
                </v-row>
               </v-container> -->
               <v-container :class=" $vuetify.breakpoint.lgAndUp ?'pa-10' : ''">
                <v-row justify="center" class="mt-13 mb-0" style="position: relative;z-index: 1000 !important;">
                  <v-hover v-slot="{ hover }">
                  <v-btn
                    color="primary"
                    class="no-text-transform "
                    height="40"
                    large
                    href="https://play.google.com/store/apps/details?id=com.processdrive.rentass"
                    elevation="3"
                   target="_blank"
                   :style="{ 'background-color': hover ? '#1E88E5' : '#ffffff', 'color': hover ? '#ffffff !important' : ''
                    , 'box-shadow': hover ? ' #1565C0 0px 5px 15px !important': ''
                    }">Get it now
                </v-btn>
                  </v-hover>
                </v-row>
                <v-row class="sample1">
                  <v-divider class="mt-1 mb-3"></v-divider>
                </v-row>
                <p class="text-center text-h5 mt-12  font-weight-bold color-changes">{{item.description}} </p>
               </v-container>
               <div class="bubbles">
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
                  <div class="bubble"></div>
              </div>
            </v-card>
          </v-container>
        </v-col>
        <v-col xl="3" lg="4" md="6" sm="6" xs="12" class="scaling-element"  v-for="(item, index) in pricingObj.pricing_subscription" :key="`subscription-${index}`">
          <v-container class="rounded-xl pa-0">
            <v-card height="660"  class="rounded-xl">
               <div :class="['img-clip white--text',  index === 0 ?'gradient-card' : index === 1 ? 'gradient-card-advance' :  'gradient-card-ultimate',]">
                 <v-container class="d-flex justify-center">
                  <div class="white--text text-h4 font-weight-bold">{{item.title }}</div>
                </v-container>
               </div>
               <v-container :class=" $vuetify.breakpoint.lgAndUp ?'pa-10' : ''">
                <p class="font-weight-bold  text-center " :class="$vuetify.breakpoint.lgAndUp ? 'text-h5' : 'hext-h5'">
                  <v-icon color="#000000"  :size="$vuetify.breakpoint.mdAndDown ? 20 : 30">mdi-currency-inr</v-icon>{{item.price}}
                </p>
                <v-row justify="center" class="ma-4 mb-0">
                  <v-hover v-slot="{ hover }">
                  <v-btn
                  class="no-text-transform"
                  height="40"
                  large
                  color="primary"
                  @click="$router.push('/demo')"
                  :style="{ 'background-color': hover ? '#1E88E5' : '#ffffff', 'color': hover ? '#ffffff !important' : ''
                    , 'box-shadow': hover ? ' #1565C0 0px 5px 15px !important': ''
                    }"
                  >Start 1 month free trial </v-btn>
                  </v-hover>
                </v-row>
                <v-row class="sample1">
                  <v-divider class="mt-1 mb-3"></v-divider>
                </v-row>
                <div class="font-weight-bold mt-3 text-left">{{ item.sub_title }}</div>
                <v-row class="ma-0" :class="$vuetify.breakpoint.mdAndDown ? 'apply-column' : ''">
                      <v-list-item-content class="" :class="$vuetify.breakpoint.smAndUp ? 'text-subtitle-1' : 'text-subtitle-2'">
                      <span v-html="item.description" class="mt-1 text-subtitle-1"></span>
                      </v-list-item-content>
                  </v-row>
               </v-container>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
/* eslint-disable */
import { api } from '../../plugins/axios'
export default {
  data () {
    return {
      title: 'Pricing',
      subtitle: 'Testing',
      content: 'working',
      pricingObj: {}
    }
  },
  mounted () {
    this.getPricing()
    this.scrollToTop()
  },
  methods: {
    getPricing () {
      api.get('/pricing')
        .then((response) => {
          if (response.data) this.pricingObj = response.data
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    },
    scrollToTop () {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Adds smooth scrolling behavior
      })
    }
  }
}
</script>

<style lang='scss'>
@import '../../assets/css/_bubbles.scss';
// @import '../../assets/css/_squares.scss';
.flex-container-apply {
  display: flex !important;
  height: 80% !important;
  align-items: center !important;
  flex-direction: column;
}
.gradient-pricing {
    background: linear-gradient(to right, #31d496, #FFA726);
}
.gradient-card {
    /* background: linear-gradient(to bottom, #102bc2, #aaafda) !important; */
        background: linear-gradient(to bottom, rgba(24, 7, 121), rgba(16, 33, 184, 0.575)) !important;
}
.gradient-card-second {
    /* background: linear-gradient(to bottom, #269922, #b2e0cb) !important; */
      background: linear-gradient(to bottom, rgb(23, 190, 18), rgba(94, 190, 147, 0.342)) !important;
}
.gradient-card-advance {
        // background: linear-gradient(to bottom, rgb(106, 7, 109), rgba(86, 33, 179, 0.575)) !important;
        background: linear-gradient(to right, #662D8C, #ED1E79) !important;
}
.gradient-card-ultimate {
  background: linear-gradient(to right, #09203F  ,  #537895) !important;
}

</style>

